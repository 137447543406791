import React, { useState } from "react"
import { graphql } from "gatsby"
import Header from "../components/Header"
import Text from "../components/Text"
import { Helmet } from "react-helmet"


const Homepage = ({ data }) => {

  // console.log(data);

  var node = {};
  
  node = data.drupal.pageNode;

  var documentSections = node.fieldDocumentSections;

  var [currentTab, setCurrentTab] = useState(0);

  return (
      <div className={ node.language.id === "en" ? "EN" :"UR"}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{node.entityLabel}</title>
          <link rel="canonical" href="https://processton.com" />
          <meta http-equiv="Cache-control" content="public, max-age=0, must-revalidate"></meta>
        </Helmet>
        <Header lang={ node.language.id === "en" ? "EN" :"UR"} />
        <div className="wide mt-16 text-brand">
          <div className="w-full flex space-x-3">
            <div className="w-3/12 pr-4 py-3 space-y-2">
              {documentSections.map((section, sectionIndex) => {                
                return (
                  <div className={"rounded text-base p-3 font-medium inline-block w-full cursor-pointer" + (sectionIndex === currentTab ? " bg-brand text-white" : " bg-gray-50 hover:bg-gray-100 text-brand" )} onClick={ (e) => setCurrentTab(sectionIndex)} onKeyDown={ (e) => setCurrentTab(sectionIndex)} role="button"  tabIndex={-1 - sectionIndex} >{section.entity.fieldDocumentSectionTitle}</div>
                )
              })}
            </div>
            <div className="w-9/12">
              {documentSections.map((section, sectionIndex) => {
                return (
                  <div className={(sectionIndex === currentTab ? "block" : "hidden" ) + " tab-"+ sectionIndex+ " current-"+ currentTab} >
                    <Text text={section.entity.fieldDocumentSectionContent.value} />
                    <div className="w-full text-right">
                      <span tabIndex={-1} role="button" onClick={ (e) => setCurrentTab(sectionIndex - 1)} onKeyDown={ (e) => setCurrentTab(sectionIndex - 1)} className={"bg-gray-50 hover:bg-gray-100 px-3 py-2 rounded mx-2 cursor-pointer" + (sectionIndex === 0 ? " hidden" : " inline-block")}>Prev</span>
                      <span tabIndex={0} role="button" onClick={ (e) => setCurrentTab(sectionIndex + 1)} onKeyDown={ (e) => setCurrentTab(sectionIndex + 1)} className={"bg-brand text-white px-3 py-2 inline-block rounded mx-1 cursor-pointer" + (sectionIndex === (documentSections.length - 1) ? " hidden" : " inline-block")}>Next</span>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
  )
}

export const query = graphql`
  query($id : String!, $language: DRUPAL_LanguageId!){
    drupal {
      pageNode: nodeById(id: $id, language: $language) {
        entityId
        entityLabel
        entityType
        entityUrl {
          path
        }
        
        language: entityLanguage {
          id
        }
        nid
        title
        sticky
        ... on DRUPAL_NodeProductDocumentation {
          nid
          uuid
          entityId
          entityLabel
          entityBundle
          fieldDocumentSections {
            entity {
              entityBundle
              entityLabel
              entityId
              ... on DRUPAL_ParagraphDocumentSection {
                id
                entityId
                entityLabel
                fieldDocumentSectionTitle
                fieldDocumentSectionContent {
                  format
                  processed
                  summary
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Homepage
